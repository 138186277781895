<template>
  <div class="form-group">
    <!-- Label -->
    <div class="row">
      <template v-if="label">
        <div class="col">
          <label v-bind:for="componentId" class="custom-label">{{
            label
          }}</label>
        </div>
        <div class="col-auto" v-if="required && error">
          <small class="text-danger"
            ><i>{{ $t("requiredArea") }}</i></small
          >
        </div>
      </template>
    </div>
    <!-- Label -->

    <div class="position-relative" v-click-outside="onClose">
      <div
        class="input-group"
        v-bind:class="{ 'input-group-sm': size == 'sm' }"
      >
        <!-- Prepend -->
        <div v-if="prepend" class="input-group-prepend">
          <span class="input-group-text" v-html="prepend"></span>
        </div>
        <!-- Prepend -->

        <!-- Input -->
        <input
          type="text"
          class="form-control rounded-right"
          v-bind:class="{
            'border-danger': error,
            'form-control-sm': smallSize,
          }"
          v-bind="$attrs"
          v-bind:id="componentId"
          v-bind:placeholder="placeholder"
          v-bind:value="value"
          v-on:input="onInput"
          v-on:blur="onBlur"
          v-on:focus="onFocus"
        />
        <!-- Input -->

        <!-- Result Count -->
        <ResultCount
          v-if="showResultCount"
          v-bind:show-spinner="showSpinner"
          v-bind:result-length="getResult().length"
          v-bind:size="size"
        />
        <!-- Result Count -->

        <!-- Result Card -->
        <ResultCard
          v-if="showDropdown && value && getResult().length"
          v-bind:filter-list="getResult()"
          v-bind:result-prop="resultProp"
          v-on:on-select="onSelect"
        />
        <!-- Result Card -->

        <!-- Append -->
        <div v-if="append" class="input-group-append">
          <span class="input-group-text" v-html="append"></span>
        </div>
        <!-- Append -->
      </div>
    </div>
  </div>
</template>
<script>
import ResultCount from "./components/ResultCount/Index";
import ResultCard from "./components/ResultCard/Index";
import { mapState, mapActions } from "vuex";

export default {
  name: "WeSearchInput",
  data() {
    return {
      value: null,
      searching: false,
      showDropdown: false,
      results: [],
      safeResult: [],
    };
  },
  components: {
    ResultCount,
    ResultCard,
  },
  props: {
    resultProp: {
      type: String,
      default: "name",
    },
    label: {
      default: "",
    },
    customParam: {
      default: null,
    },
    clearable: {
      default: true,
    },
    filteredData: {
      default: () => [],
    },
    placeholder: {
      default: "",
    },
    minLength: {
      default: 3,
    },
    prepend: {
      default: "",
    },
    append: {
      default: "",
    },
    delay: {
      default: 1000,
    },
    required: {
      default: false,
    },
    error: {
      default: false,
    },
    size: {
      default: "lg",
    },
    name: {
      required: true,
      default: "",
    },
    smallSize: {
      default: false,
    },
    additional: {
      default: null,
    },
    language: {
      default: "tr",
    },
  },
  methods: {
    ...mapActions("shared", ["search"]),
    onInput(e) {
      this.value = e.target.value;
      this.doSearch(e.target.value, this.delay);
    },
    doSearch(text, timeout) {
      this.searching = false;
      let localTimeout = 0;
      if (timeout) {
        localTimeout = timeout;
      }

      if (text && text.trim().length) {
        if (text.trim().length >= this.minLength) {
          this.emitSearch(text, localTimeout);
        }
      }
    },
    emitSearch(text, timeout) {
      clearTimeout(this.timer);

      this.searching = true;
      this.clearResult();
      this.timer = setTimeout(() => {
        clearTimeout(this.timer);

        const routeParam = this.searchState.routes[this.name];
        const route = routeParam.route;
        const searchKey = routeParam.key;
        const withCategory =
          routeParam.hasOwnProperty("category_id") && this.additional;

        const data = {};
        data[searchKey] = text;
        data.lang = this.language;
        if (this.customParam) {
          for (const [key, value] of Object.entries(this.customParam)) {
            data[key] = value;
          }
        }

        if (withCategory) data["category_id"] = this.additional;

        this.search({
          route: route,
          form: data,
          onSuccess: (result) => {
            if (result && result.data) {
              if (result.data.hasOwnProperty("items")) {
                this.results = result.data.items;
              } else {
                this.results = result.data;
              }
              this.safeResult = this.results
            }
          },
          onFinish: () => {
            this.searching = false;
          },
        });
      }, timeout);
    },
    clearResult() {
      this.results.length = 0;
    },
    getResult(data = this.filteredData) {
      let result = [];
      this.safeResult.forEach((element) => {
        if (!data.find((A) => A.id == element.id)) {
          result.push(element);
        }
      });
      return result;
    },
    selectData(data) {
      this.results = this.getResult(data);
    },
    onBlur() {
      if (this.clearable) {
        setTimeout(() => {
          this.showDropdown = false;
        }, 200);
      }
    },
    onClose() {
      this.showDropdown = false;
    },
    onFocus() {
      if (this.value && this.value.length && this.getResult().length) {
        this.showDropdown = true;
      }
    },
    onSelect(data) {
      if (this.clearable) this.value = "";
      this.$emit("on-select", data);
    },
  },
  computed: {
    ...mapState({
      searchState: (state) => state.shared.search,
    }),
    componentId() {
      return "we-search-input" + helper.getRandomInteger(1, 9999);
    },
    showSpinner() {
      return this.searching && !this.getResult().length;
    },
    showResultCount() {
      return this.value && this.value.length >= this.minLength;
    },
  },
  watch: {
    results: function (newVal) {
      if (newVal && newVal.length) {
        this.showDropdown = true;
      }
    },
  },
};
</script>
