<template>
  <div class="dropdown-menu show shadow-sm col-12 custom-scrollbar result-card">
    <span
      class="btn dropdown-item py-2"
      v-for="(data, i) in filterList"
      v-bind:key="componentId + '-' + i"
      v-on:click="select(data)"
    >
      <div class="row align-items-center">
        <div class="col-auto">
          <div v-if="data[resultProp]" v-html="data[resultProp]"></div>
        </div>
      </div>
    </span>
  </div>
</template>
<script>
export default {
  name: "ResultCard",
  props: {
    resultProp: {
      default: "name",
    },
    filterList: {
      default: () => [],
    },
  },
  methods: {
    select(data) {
      this.$emit("on-select", data);
    },
  },
  computed: {
    componentId() {
      return "we-search-result" + helper.getRandomInteger(1, 9999);
    },
  },
};
</script>
<style lang="scss" scoped>
.result-card {
  max-height: 180px;
  overflow: auto;
}
</style>
