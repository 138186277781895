<template>
  <!-- Result Count -->
  <div
    v-bind:class="{
      'result-count': size !== 'sm',
      'result-count-sm': size == 'sm',
    }"
  >
    <div v-if="showSpinner">
      <i class="fas fa-spinner fa-spin"></i>
    </div>
    <div v-else>
      <template v-if="size !== 'sm'">
        <div v-if="resultLength">
          <span class="badge badge-pill alert-primary"
            >{{ resultLength }} {{ $t("result") }}</span
          >
        </div>
        <div v-else>
          <span class="badge badge-pill alert-dark">{{
            $t("thereWereNoResults")
          }}</span>
        </div>
      </template>
      <template v-else>
        <div v-if="resultLength">
          <span class="badge badge-pill alert-primary">{{ resultLength }}</span>
        </div>
        <div v-else>
          <span
            class="badge badge-pill alert-danger"
            v-tooltip.top="$t('thereWereNoResults')"
            ><i class="fas fa-times"></i
          ></span>
        </div>
      </template>
    </div>
  </div>
  <!-- ./Result Count -->
</template>
<script>
export default {
  name: "ResultCount",
  props: {
    showSpinner: {
      default: false,
    },
    resultLength: {
      default: 0,
    },
    size: {
      default: "",
    },
  },
};
</script>
<style lang="scss" scoped>
.result-count {
  position: absolute;
  right: 10px;
  z-index: 1060;
  top: 7px;
}
.result-count-sm {
  position: absolute;
  right: 8px;
  z-index: 1060;
  top: 4px;
}
</style>
